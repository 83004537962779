import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
// px2rem 自适应
import 'lib-flexible'
import axios from 'axios'
Vue.prototype.$ajax= axios

Vue.use(Vant);
import App from './app.vue'
Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')