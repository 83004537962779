<template>
  <div class="con-box">
    <!-- <img src="./images/imgbg.png"> -->
    <div class="header">
      <div class="logo-div">
        <span class="logo-name">版飞跃</span>
        <span class="name">数智出版云</span>
      </div>
      <div class="nav-bar">
        <div>首页</div>
        <div>产品与服务</div>
        <div>解决方案</div>
        <div>案例</div>
        <div>合作与生态</div>
        <div>关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="./images/banner.png"
           alt="">
    </div>
    <div style="clear:both"></div>
    <div class="tip-div">
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/1.png"
               alt=""
               class="img-1"></div>
        <div class="tip-title">口碑信任</div>
        <div class="tip-des">众多出版机构选择</div>
      </div>
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/2.png"
               alt=""
               class="img-2"></div>
        <div class="tip-title">技术领先</div>
        <div class="tip-des">多年技术沉淀</div>
      </div>
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/3.png"
               alt=""
               class="img-3"></div>
        <div class="tip-title">服务专业</div>
        <div class="tip-des">一对一客户经理</div>
      </div>
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/4.png"
               alt=""
               class="img-4"></div>
        <div class="tip-title">案例众多</div>
        <div class="tip-des">众多成功案例</div>
      </div>
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/5.png"
               alt=""
               class="img-5"></div>
        <div class="tip-title">系统稳定</div>
        <div class="tip-des">百度云保驾护航</div>
      </div>
      <div class="tip-div-item">
        <div class="img-div"><img src="./images/6.png"
               alt=""
               class="img-6"></div>
        <div class="tip-title">IP扶持</div>
        <div class="tip-des">头部IP流量扶持</div>
      </div>
    </div>
    <div style="clear:both"></div>
    <!-- 描述 -->
    <div class="content-width">
      <div class="title-des">提供从内容到变现的全链条数字出版解决方案</div>
      <div>
        <div class="process">
          <div class="process-div">数字出版<span>内容</span></div>
          <div class="process-line"></div>
          <div class="process-div">数字出版<span>产品</span></div>
          <div class="process-line"></div>
          <div class="process-div">数字出版<span>营销</span></div>
        </div>
      </div>
      <div class="flex-div">
        <div class="flex-left">
          <img src="./images/top-1.png"
               alt=""
               class="top-1">
        </div>
        <div class="flex-right">
          <div class="flex-content">
            <div class="flex-title"> <span class="i-icon"></span> 数字出版内容解决方案</div>
            <div class="flex-des">提供各种类型的数字出版内容的整理、编辑、制作及管理的全方位功能。支持从纸质内容转数字内容的制作，也支持从数字内容到数字内容的深加工制作。</div>
          </div>
          <div class="flex-content">
            <div class="flex-title"><span class="i-icon"></span>数字出版产品解决方案</div>
            <div class="flex-des">提供各种类型产品的快速开发、部署、发布。支持众多数字产品模板的一键开发部署，包含App、H5、公众号、小程序等产品。</div>
          </div>
          <div class="flex-content">
            <div class="flex-title"><span class="i-icon"></span>数字出版营销解决方案</div>
            <div class="flex-des">打通各大互联网营销平台，能够让出版机构快速实现数字产品的全网营销，营销任务统一管理、协同分配、数据集中管理，并形成协同效应。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="content-width">
        <div class="title-des">大幅降低数字出版门槛</div>
        <div class="flex-div">
          <div class="flex-right">
            <div class="flex-content">
              <div class="flex-title"><span class="i-icon"></span>不用考虑组建完整的产品团队及开发团队</div>
              <div class="flex-des">版飞跃数智出版云提供丰富完整的数字出版产品解决方案，出版机构只要选择需要的产品类型便可快速生产产品。只要有编辑就可以做出可靠的数字产品，再也不用考虑建立完整的产品经理、UI设计师、技术开发、技术维护团队。大幅降低出版机构的数字出版门槛。</div>
            </div>
            <div class="flex-content">
              <div class="flex-title"><span class="i-icon"></span>不用考虑长期的产品迭代及运维负担</div>
              <div class="flex-des">版飞跃数智出版云提供长期的产品迭代及运维保障，使得出版机构能够无需考虑产品的长期迭代及运维负担，可以把精力和资金投入到内容策划和创作上来，大幅降低出版机构的数字出版门槛。</div>
            </div>
          </div>
          <div class="flex-left">
            <img src="./images/top-2.png"
                 alt=""
                 class="top-2">
          </div>
        </div>
      </div>
    </div>
    <div class="content-width">
      <div class="title-des">大幅减少数字出版试错成本</div>
      <div class="flex-div">
        <div class="flex-left">
          <img src="./images/top-3.png"
               alt=""
               class="top-3">
        </div>
        <div class="flex-right">
          <div class="flex-content">
            <div class="flex-title"><span class="i-icon"></span>无需一次投入大额成本，小步试错。</div>
            <div class="flex-des">版飞跃数智出版云提供按需购买云服务的创新模式，出版机构可以根据自身阶段性的需要购买所需服务。无需一次性投入大额的开发成本，小步</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="content-width">
        <div class="title-des">快速见成效</div>
        <div class="flex-div">
          <div class="flex-right">
            <div class="flex-content">
              <div class="flex-title"><span class="i-icon"></span>打通全网营销平台，快速见成效。</div>
              <div class="flex-des">版飞跃数智出版云打通全网营销平台，能够把出版机构的数字出版产品快速分发到用户手中，快速实现数字出版产品的变现，使得数字出版产品能够快速见成效。</div>
            </div>
          </div>
          <div class="flex-left">
            <img src="./images/top-4.png"
                 alt=""
                 class="top-4">
          </div>
        </div>
      </div>
    </div>
    <div class="agency">
      <div class="agency-title">版飞跃正在助力众多出版机构</div>
      <div class="agency-des">（机构排名不分先后）</div>
      <div class="agency-image">
        <div class="image-div">
          <img src="./images/agency-1.png"
               alt=""
               srcset="">
          <img src="./images/agency-2.png"
               alt=""
               srcset="">
          <img src="./images/agency-3.png"
               alt=""
               srcset="">
          <img src="./images/agency-4.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-5.png"
               alt=""
               srcset="">
          <img src="./images/agency-6.png"
               alt=""
               srcset="">
          <img src="./images/agency-7.png"
               alt=""
               srcset="">
          <img src="./images/agency-8.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-9.png"
               alt=""
               srcset="">
          <img src="./images/agency-10.png"
               alt=""
               srcset="">
          <img src="./images/agency-11.png"
               alt=""
               srcset="">
          <img src="./images/agency-12.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-13.png"
               alt=""
               srcset="">
          <img src="./images/agency-14.png"
               alt=""
               srcset="">
          <img src="./images/agency-15.png"
               alt=""
               srcset="">
          <img src="./images/agency-16.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-17.png"
               alt=""
               srcset="">
          <img src="./images/agency-18.png"
               alt=""
               srcset="">
          <img src="./images/agency-19.png"
               alt=""
               srcset="">
          <img src="./images/agency-20.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-21.png"
               alt=""
               srcset="">
          <img src="./images/agency-22.png"
               alt=""
               srcset="">
          <img src="./images/agency-23.png"
               alt=""
               srcset="">
          <img src="./images/agency-24.png"
               alt=""
               srcset="">
        </div>
        <div class="image-div">
          <img src="./images/agency-25.png"
               alt=""
               srcset="">
        </div>
      </div>
    </div>
    <div class="footer">
      <p class="copyright">
        Copyright
        © 2015-2021 智慧流版权所有
      </p>

      <div class="beian">
        <!-- <span class="msg">
          <span>京ICP备15042556号-6</span></span> -->
        <a href="https://beian.miit.gov.cn"
           target="_blank"
           style="text-decoration:none;height:20px;line-height:20px;">
          <p class="lh"
             style="color:#939393;">京ICP备15042556号-6</p>
        </a>
        <a target="_blank"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030328"
           style="text-decoration:none;height:20px;line-height:20px;">
          <img src="./images/beian.png" />
          <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010802030328号</p>
        </a>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'discoveryPage',

  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/reset.css';
.header {
  display: flex;
  // justify-content: space-around;
  padding: 20px 0;
  align-items: center;
  padding-left: 32%;
  .logo-div {
    float: left;
    margin-right: 56px;
  }
  .nav-bar {
    float: left;
  }
}
.logo-div {
}
.nav-bar {
  display: flex;
  justify-content: space-around;
  color: #343434;
  font-size: 12px;
  div {
    margin-right: 30.5px;
  }
}
.logo-name {
  color: #b50000;
  font-size: 18px;
  margin-right: 2px;
}
.name {
  font-size: 17px;
  color: #333333;
}
.tip-div {
  display: flex;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
  padding-top: 47px;
  // height: 150px;
  // padding: 0 20%;
  .tip-title {
    color: #3e3e3e;
    font-size: 15px;
    margin-bottom: 11px;
  }
  .tip-des {
    color: #898989;
    font-size: 13px;
    width: 105px;
  }
  .img-div {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-1 {
    width: 36px;
  }
  .img-2 {
    width: 38px;
  }
  .img-3 {
    width: 37px;
  }
  .img-4 {
    width: 38px;
  }
  .img-5 {
    width: 34px;
  }
  .img-6 {
    width: 44px;
  }
}
.tip-div-item {
  text-align: center;
  margin-right: 27px;
}
.flex-content {
  margin-bottom: 32px;
}
.flex-title {
  color: #3b3a3a;
  font-size: 17px;
  margin-bottom: 13px;
  display: flex;
  align-content: center;
}
.flex-des {
  color: #6f6e6e;
  font-size: 13px;
}
.title-des {
  font-size: 24px;
  color: #3b3a3a;
  padding-top: 71px;
  text-align: center;
}
.content-width {
  // width: 650px;
  margin: 0 auto;

  // text-align: center;
  .top-1 {
    width: 216px;
    margin-right: 93px;
    margin-bottom: 20px;
  }
  .top-2 {
    width: 286px;
    margin-left: 71px;
  }
  .top-3 {
    width: 266px;
    margin-right: 93px;
    margin-bottom: 87px;
  }
  .top-4 {
    width: 206px;
    margin-left: 106px;
    margin-bottom: 87px;
  }
  .flex-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 44px;
    // width: 70%;
    width: 720px;
  }
}
.banner {
  img {
    // height: 224px !important;
  }
}
.agency {
  background: #b80002;
  padding: 60px 200px;
  .agency-title {
    text-align: center;
    color: white;
    font-size: 23px;
    margin-bottom: 32px;
  }
  .agency-des {
    text-align: center;
    color: white;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .agency-image {
    // display: flex;
    // flex-wrap: wrap;
    img {
      width: 211px;
      height: 60px;
      // margin-right: 21px;
      // margin-bottom: 21px;
    }
  }

  .image-div {
    display: flex;
    width: 900px;
    margin: 0 auto;
    margin-bottom: 21px;
    justify-content: space-between;
  }
}
.i-icon {
  display: inline-block;
  width: 3px;
  height: 14px;
  margin-top: 5px;
  margin-right: 10px;
  background: #b80002;
}
.process {
  display: flex;
  align-items: center;
  margin-top: 42px;
  justify-content: center;
}
.process-div {
  width: 182px;
  height: 86px;
  background: #e0e0e0;
  border-radius: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 86px;
  border: 1px solid rgba(224, 224, 224, 1);
  color: #535353;
  span {
    color: #b50000;
  }
}
.process-line {
  width: 110px;
  height: 1px;
  background: #b50000;
}
.bg-gray {
  background: #f5f5f5;
  padding-bottom: 94px;
}
.con-box {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // background: #f5f5f5;
  background: white;
  img {
    width: 100%;
    // height: auto;
  }
  .footer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    .copyright {
      color: #999;
    }
    .msg {
      color: #999;
      // margin-top: 10px;
      span {
        margin: 0 10px;
      }
    }
    .beian {
      // width:300px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 20px 0;
      padding-top: 10px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>